import clearLocalStorageMixin from '~/mixins/clearLocalStorageMixin'
export default async function({
    app,
    route,
    redirect,
    store,
    $auth
}) {
    const isAuthenticated = !!store.state.auth.user
    const isToken = localStorage.getItem('auth._token.local')
    localStorage.setItem('slug', route.params.slug)
    store.dispatch('tenant/clear')
    const linkCode = localStorage.getItem('linkCode')

    const slug = route.params.slug
    let role = JSON.parse(localStorage.getItem('vuex'))
    role = role.profile.company.role

    if (isAuthenticated && isToken !== 'false') {
        try {
            const response = await app.$axios.get(
                '/auth/enrich-link/' + slug
            )
            store.dispatch('profile/SET_STATEMENT', response.data.user.statement)
            store.dispatch('profile/SET_COMPANYADDED', response.data.user.has_company)
            store.dispatch('profile/SET_SOLE_TRADER', response.data.user.soletrader_enabled)
            store.dispatch('profile/SET_STATEMENT_ENABLED', response.data.user.statement_enabled)
            store.dispatch('profile/SET_IS_ADDRESS_AUTOCOMPLETE_ENABLED', response.data.user.address_autocomplete_enabled)


            const companyDetails = store.getters['profile/GET_COMPANY']
            const localIsStatementDeclared = localStorage.getItem('isDeclaration')
            const isBusinessTypeSelected = localStorage.getItem('isBusinessTypeSelected')
            if (response.data.user.has_company && response.data.user.statement_declared && route.name !== 'portal-find-company-slug-add' && route.name !== 'portal-find-company-slug-select-business-type') {
                redirect(`/portal/my-companies/${slug}`)
            } else if (role === 'identity') {
                redirect(`/portal/identity/${slug}/match/${linkCode}`)
            } else if (route.name === 'portal-find-company-slug-add') {
                if (response.data.user.statement_enabled && (!response.data.user.statement_declared && !localIsStatementDeclared)) {
                    redirect(`/portal/find-company/${slug}/declaration`)
                } else if (response.data.user.soletrader_enabled) {
                    if (isBusinessTypeSelected) {
                        return true
                    } else {
                        redirect(`/portal/find-company/${slug}/select-business-type`)
                    }
                } else {
                    store.dispatch('profile/SET_BUSINESS_TYPE', 'company')
                }
                // redirect(`/portal/find-company/${slug}/select-business-type`)
            } else if (route.name === 'portal-find-company-slug-select-business-type') {
                if (response.data.user.statement_enabled && (!response.data.user.statement_declared && !localIsStatementDeclared)) {
                    redirect(`/portal/find-company/${slug}/declaration`)
                } else if (!response.data.user.soletrader_enabled) {
                    store.dispatch('profile/SET_BUSINESS_TYPE', 'company')
                    redirect(`/portal/find-company/${slug}/find-company-account-created`)
                }

            } else if (route.name === 'portal-find-company-slug-declaration') {
                if (response.data.user.statement_enabled && (!response.data.user.statement_declared && !localIsStatementDeclared)) {
                    return true
                } else if (response.data.user.has_company) {
                    redirect(`/portal/my-companies/${slug}`)
                } else if (response.data.user.soletrader_enabled) {
                    if (isBusinessTypeSelected) {
                        redirect(`/portal/find-company/${slug}/find-company-account-created`)
                    } else {
                        redirect(`/portal/find-company/${slug}/select-business-type`)
                    }
                } else {
                    redirect(`/portal/find-company/${slug}/find-company-account-created`)
                }
            } else if (route.name === 'portal-find-company-slug-find-company-account-created') {
                if (response.data.user.statement_enabled && (!response.data.user.statement_declared && !localIsStatementDeclared)) {
                    redirect(`/portal/find-company/${slug}/declaration`)
                } else if (response.data.user.soletrader_enabled && !isBusinessTypeSelected) {
                    redirect(`/portal/find-company/${slug}/select-business-type`)
                }
            }

        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                const slug = localStorage.getItem('slug')
                const vuex = JSON.parse(localStorage.getItem('vuex'))
                if (vuex) {
                    store.commit('profile/RESET_FIELD', vuex.profile.company)
                    store.commit('stepper/RESET_FIELD', vuex.stepper)
                }
                clearLocalStorageMixin.methods.clearLocalStorageExceptKey('slug')
                redirect(`/portal/find-company/${slug}`)
                await $auth.logout()
            }
        }
    } else {
        const vuex = JSON.parse(localStorage.getItem('vuex'))
        store.commit('profile/RESET_FIELD', vuex?.profile?.company)
        clearLocalStorageMixin.methods.clearLocalStorageExceptKey('slug')
        redirect(`/portal/find-company/${slug}`)
        await $auth.logout()
    }
}
